import "./style.css"
import line from "../../imagenes/linea_fouter.png"
import fac from "../../imagenes/fac.svg"
import ins from "../../imagenes/ins.svg"
import you from "../../imagenes/You.svg"
import { Button, Container, IconButton } from '@mui/material';
import Cicaficultura from '../../imagenes/foouter/Cicaficultura-02 1.SVG'
import Alcaldí from '../../imagenes/foouter/Logo Alcaldí del Patía 1.SVG'
import Cafeteros from '../../imagenes/foouter/Logo Cafeteros 1.SVG'
import logSGR from '../../imagenes/foouter/sgr 1.SVG'
import casac from '../../imagenes/foouter/CASAC.png'
import unicauca from '../../imagenes/foouter/Logo Universidad del Cauca 1.SVG'

const Fouter = () => {

    return (
        <div>
            <Container sx={{ marginBottom: "1%" }} >
                <div align="center">
                    <Button target='_blank' href="https://www.sgr.gov.co/"> <img src={logSGR} width='140' alt='logSGR' /> </Button>
                    <Button style={{ width: "12%" }} target='_blank' href="https://www.unicauca.edu.co/"><img src={unicauca} width='160' alt='unicauca' /></Button>
                    <Button style={{ width: "12%" }} target='_blank' href="https://www.facebook.com/share/ZwgJWAfH7XnsD8Wx/?mibextid=eQY6cl"><img src={casac} width='100' alt='unicauca' /></Button>
                    <Button target='_blank' href="https://patia-cauca.gov.co/Paginas/Inicio.aspx"><img src={Alcaldí} width='110' alt='Alcaldí' /></Button>
                    <Button target='_blank' href="https://federaciondecafeteros.org/wp/"> <img src={Cafeteros} width='140' alt='Cafeteros' /> </Button>
                    <Button target='_blank' href="https://cicaficultura.co/"> <img src={Cicaficultura} width='140' alt='Cicaficultura' /></Button>
                </div>
            </Container>

            <div style={{ display: "flex", flexDirection: "column", marginLeft: "95%", marginTop: -180, marginBottom: 5 }}>
                <IconButton target='_blank' href="https://www.facebook.com/share/ZwgJWAfH7XnsD8Wx/?mibextid=eQY6cl">
                    <img src={fac} alt="img" height={35} />
                </IconButton>
                <IconButton target='_blank' href="https://www.instagram.com/cicaficultura?igsh=MTZpNXVnbDl0MTcwbA==">

                    <img src={ins} alt="img" height={35} />
                </IconButton>
                <IconButton target='_blank' href="https://youtube.com/@comunicacionescicaficultur5192?si=v38eeBp_7eLxiqw_">
                    <img src={you} alt="img" height={35} />

                </IconButton>

            </div>
            <img src={line} alt="img" height={35} />
        </div>

    )
}
export default Fouter