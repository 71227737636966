import "./style.css"
import Fouter from "../foouter"
import Headers from "../headers"
import { Box, Button, Divider } from "@mui/material"
import quienesSomos from "../../imagenes/quienesSomos.svg"
import Cicaficultura from '../../imagenes/foouter/Logo Universidad del Cauca 1.SVG'
import Alcaldí from '../../imagenes/foouter/Logo Alcaldí del Patía 1.SVG'
import Cafeteros from '../../imagenes/foouter/Logo Cafeteros 1.SVG'
import logSGR from '../../imagenes/foouter/sgr 1.SVG'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useEffect, useLayoutEffect, useState } from "react";
import Banner from '../componet/banner';
import { apiMapsall, apiGallery, urlBase, compUrls } from "../../controller/autentications/urlsServer"
import axios from 'axios';

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const Products = () => {

    const [widthResize, setWidthResize] = useState(window.innerWidth)
    const [publication, setpublication] = useState([]);
    const [gallery, setGallery] = useState([]);

    const resize = () => {
        setWidthResize(window.innerWidth)
    }

    const getBanner = async () => {
        await axios.get(apiMapsall, compUrls)
            .then(response => {
                setpublication(response.data);
            });
    }

    const getGallery = async () => {
        await axios.get(apiGallery + "Quienes somos", compUrls)
            .then(response => {
                setGallery(response.data);
            });
    }


    const itemData2 = gallery.length > 0 ? [

        {

            img: urlBase +"/api"+ gallery[0].File,
            title: gallery[0].title,
            rows: 5,

        },
        {
            img: urlBase +"/api"+ gallery[1].File,
            title: gallery[1].title,
            rows: 5,

        },
        {
            img: urlBase +"/api"+ gallery[2].File,
            title: gallery[2].title,
            rows: 5,

        },
        {
            img: urlBase +"/api"+ gallery[3].File,
            title: gallery[3].title,
            rows: 5,

        }
    ] : null;
    useEffect(() => {
        getBanner();
        getGallery();
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', resize);
    }, []);

    return (
        <div>
            <div className="headers">
                <Headers index={1} />
            </div>
            <br /><br />
            <Box style={{
                display: 'flex',
                alignItems: 'center',
                width: "100%", background: "#1B6E81"

            }}>
                <div style={{ textAlign: "left", marginLeft: "5%", background: "#1B6E81", width: "50%" }}>
                    <div style={{ marginLeft: "5%", width: "100%" }} >
                        <p style={{ color: "#ffff", fontFamily: 'Montserrat', fontSize: 50 }}><strong>¿Qué es CASAC?</strong></p>
                        <p style={{ marginTop: -50, width: "100%", color: "#ffff", fontFamily: 'Montserrat', fontSize: 18 }}>
                            Somos un equipo de docentes, investigadores, estudiantes y comunidades caficultoras, líderes campesinos, integrantes del Comité departamental de Cafeteros del Cauca y de la Alcaldía de Patía,
                            <strong> interesados en fortalecer la caficultura agroecológica como estrategia de adaptación al cambio climático </strong>
                            en el departamento del Cauca.
                        </p>
                    </div>
                </div>
                <Divider orientation="vertical" />
                <img style={{ marginLeft: "10%" }} src={quienesSomos} alt="Descrube_casa" />
            </Box>
            <br />
            <Box >
                <div style={{ textAlign: "center" }}>
                    <h1 style={{ marginLeft: "5%", color: "#1B6E81", fontFamily: 'Montserrat', fontSize: 45 }}>Organizaciones</h1>
                </div>
                <center>
                    <div style={{ width: "80%" }}>
                        <p style={{ color: "#012B31", fontFamily: 'Montserrat', fontSize: 20 }}>En el proyecto participan desde su formulación: Como entidad financiadora: Minciencias a través de Sistema General de Regalías, La Universidad del Cauca como ejecutor y entidades aliadas como La Alcaldía Municipal de Patia y el comité de cafeteros del Cauca.</p>
                        <br />
                    </div>
                </center>

                <div align="center" >
                    <Button disabled> <img src={logSGR} width='260' alt='logSGR' /> </Button>
                    <Button disabled> <img src={Cicaficultura} width='260' alt='Cicaficultura' /></Button>
                    <Button disabled><img src={Alcaldí} width='170' alt='Alcaldí' /></Button>
                    <Button disabled> <img src={Cafeteros} width='260' alt='Cafeteros' /> </Button>
                </div>

            </Box>
            <br />


            <div>{widthResize > 750 ? "" : <br />}
                <Banner data={publication} />
                <br />
            </div>
            <br />

            <div>

                <div style={{ textAlign: "center" }}>
                    <p style={{ marginLeft: "5%", fontFamily: 'Montserrat', color: "#1B6E81", fontSize: 45 }}><strong> Público objetivo </strong></p>
                </div>
                <center>
                    <div style={{ width: "80%", marginTop: "-1%" }}>
                        <p style={{ color: "#012B31", fontFamily: 'Montserrat', fontSize: 20 }}>Se trata de las instituciones públicas y privadas, organizaciones e instituciones educativas que están trabajando en Agroecología, economías solidarias, educación con pertinencia. Este proyecto va dirigido a grupos de investigación, docentes, comunidad académica en general y comunidades rurales interesadas en fortalecer estrategias agroecológicas para adaptarse al cambio climático.</p>
                        <br />
                    </div>
                </center>
            </div>
            <div>
                <ImageList

                    sx={{ width: "100%" }}
                    variant="quilted"
                    cols={4}
                    rowHeight={121}
                >
                    {
                        gallery.length > 0 ?
                            itemData2.map((item) => (
                                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                    <img
                                        {...srcset(item.img, 1, item.rows, item.cols)}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))
                            : ""
                    }
                </ImageList>

            </div>
            <div className="fouter">
                <Fouter />
            </div>
        </div>
    )
}
export default Products
