import * as React from 'react';
import { Box, Divider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { urlBase } from '../../controller/autentications/urlsServer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,

};

const BootstrapButtonver = styled(Button)({
  color: '#fff',
  height: "2%",
  width: "25%",
  position: "static",
  fontSize: 20,
  marginLeft: "75%",
  backgroundColor: "#9CC430",
  textTransform: "inherit",
  cursor: 'pointer',
  fontFamily: 'Montserrat, sans-serif',
  borderRadius: 17,
  '&:hover': {
    backgroundColor: "#a8d600", // Mantén el mismo color de fondo al pasar el mouse
  },
});

export default function ModalInfo(datalits) {
  return (
    <Box sx={style}>
      <div style={{ background: datalits.item === 1 ? '#9CC430' : datalits.item === 2 ? '#012B31' : "#1B6E81", maxHeight: 500, overflowY: 'scroll' }}>
        <div style={{ marginLeft: "5%", width: "90%" }} >
          {datalits.datalits.length > 0 ?
            datalits.datalits.map((data) => (
              data.disable === true ?
                <div style={{ width: "90%" }}>

                  <div style={{ width: "80%" }}>
                    <p style={{ color: "#fff", fontSize: 25, fontFamily: 'Montserrat, sans-serif' }}><strong>

                      {data.title}</strong></p>
                  </div>
                  {datalits.item === 1 ?
                    <div style={{ marginLeft: "5%", marginTop: "-10%", width: "100%" }}>
                      <BootstrapButtonver sx={{ background: "#009940" }} target='_blank' href={urlBase +"/api"+ data.File}>Descarga</BootstrapButtonver>
                    </div>
                    : ""}
                  {datalits.item === 2 ?
                    <div style={{ marginLeft: "5%", marginTop: "-10%", width: "100%" }}>
                      <BootstrapButtonver target='_blank' href={"https://www.youtube.com/embed/" + data.link}>ver</BootstrapButtonver>
                    </div>
                    : ""}
                  <p style={{ color: "#fff", fontSize: 22, fontFamily: 'Montserrat, sans-serif' }}>{data.context}</p>
                  <Divider orientation="horizontal" style={{ color: "#fff", background: "#fff", border: '1px solid' }} />
                  <br />
                </div>
                : ""
            ))
            :
            <p>No Data</p>
          }
        </div>
      </div>
    </Box>

  );
}