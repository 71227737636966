import "./style.css"
import Fouter from "../foouter"
import Headers from "../headers"
import { Box, Divider, Button, Modal } from "@mui/material"
import descubreCasac from "../../imagenes/descubre_casac.png"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { apiDocumentary, apiBitacora, apiGallery, urlBase, compUrls } from "../../controller/autentications/urlsServer"
import axios from 'axios';
import { useEffect, useState } from "react"
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import ModalInfo from '../componet/modalinfo'

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}
const BootstrapButton = styled(Button)({
    color: '#fff',
    height: "2%",
    width: "25%",
    margin: 20,
    position: "static",
    fontSize: 25,
    marginLeft: "75%",
    backgroundColor: "#9CC430",
    textTransform: "inherit",
    cursor: 'pointer',
    fontFamily: 'Montserrat, sans-serif',
    borderRadius: 17,
    '&:hover': {
        backgroundColor: "#a8d600", // Mantén el mismo color de fondo al pasar el mouse
    },
});
const BootstrapButtonver = styled(Button)({
    color: '#fff',
    height: "2%",
    width: "5%",
    position: "static",
    fontSize: 20,
    marginLeft: "75%",
    backgroundColor: "#9CC430",
    textTransform: "inherit",
    cursor: 'pointer',
    fontFamily: 'Montserrat, sans-serif',
    borderRadius: 17,
    '&:hover': {
        backgroundColor: "#a8d600", // Mantén el mismo color de fondo al pasar el mouse
    },
});

const customTheme = createTheme({
    palette: {

        primary: {
            main: '#9CC430',
        },
    },
});

const Product = () => {

    const [bitacora, setBitacora] = useState([]);
    const [documentales, setDocumentales] = useState([]);
    const [infModal, setInfModal] = useState(false);
    const [indexdata, setIndexdata] = useState(0);
    const [indexVideo, setindexVideo] = useState(0);
    // const [pathVideo, setPathVideo] = useState("");
    const [gallery, setGallery] = useState([]);

    const GetPublications = async () => {
        await axios.get(apiBitacora, compUrls)
            .then(response => {
                setBitacora(response.data.reverse());
            });
        await axios.get(apiDocumentary, compUrls)
            .then(response => {
                setDocumentales(response.data.reverse());
            });
        // await axios.get(apiPathVideo, compUrls)
        //     .then(response => {
        //         setPathVideo(response.data.reverse());
        //     });
        await axios.get(apiGallery + "producto", compUrls)
            .then(response => {
                setGallery(response.data);
            });



    }
    const hiddenMedia = () => {
        setInfModal(!infModal);

    }

    const publucationsmodal = (
        <div style={{ width: "50%" }}>
            <ModalInfo datalits={indexdata === 1 ? bitacora : documentales} item={indexdata} />
        </div>
    )
    const itemData = gallery.length > 0 ? [

        {
            img: urlBase +"/api"+ gallery[0].File,
            title: gallery[0].title,
            rows: 3,

        },
        {
            img: urlBase +"/api"+ gallery[1].File,
            title: gallery[1].title,
            rows: 3,

        },
        {
            img: urlBase +"/api"+ gallery[2].File,
            title: gallery[2].title,
            rows: 3,

        }, {
            img: urlBase +"/api"+ gallery[3].File,
            title: gallery[3].title,
            rows: 4,
            cols: 2,
        },
        {
            img: urlBase +"/api"+ gallery[4].File,
            title: gallery[4].title,
            rows: 4
        }
    ] : null;

    useEffect(() => {
        GetPublications();
    }, []);

    let count = 0;
    let count2 = 0;
    return (
        <div className="conteiner">
            <div className="headers">
                <Headers index={3} />
            </div>
            <br /><br /><br /><br /><br />
            <Box style={{
                display: 'flex',
                alignItems: 'center',
                width: "100%"

            }}>
                <div style={{ display: "flex", flexDirection: "column", width: "51%" }}>
                    <img style={{ marginLeft: "10%" }} src={descubreCasac} alt="Descrube_casa" />
                    <ThemeProvider theme={customTheme}>
                        <BootstrapButton onClick={() => { setInfModal(true); setIndexdata(1) }}>Ver todas</BootstrapButton>
                    </ThemeProvider>
                </div>
                <Divider orientation="vertical" />
                <div style={{ textAlign: "left", marginLeft: "5%", background: "#9CC430", width: "50%" }}>
                    <div style={{ marginLeft: "5%", width: "80%" }} >
                        {
                            bitacora.length > 0 &&
                            bitacora.map((data) => {
                                if (count < 3 && data.disable === true) {
                                    count++;
                                    return (
                                        <div>
                                            <div>
                                                <div style={{ width: "90%" }}>
                                                    <p style={{ color: "#fff", fontSize: 25, fontFamily: 'Montserrat, sans-serif' }}><strong>
                                                        {data.title}</strong></p>
                                                </div>
                                                <div style={{ marginLeft: "5%", marginTop: "-10%", width: "100%" }}>
                                                    <BootstrapButtonver sx={{ background: "#009940", width: "20%" }} target='_blank' href={urlBase +"/api"+ data.File}>Descarga</BootstrapButtonver>
                                                </div>
                                                <p style={{ color: "#fff", fontSize: 15, fontFamily: 'Montserrat, sans-serif' }}>{data.context}</p>
                                                <Divider orientation="horizontal" style={{ color: "#fff", background: "#fff", border: '1px solid' }} />
                                            </div>
                                            {
                                                count === 3 ?
                                                    <div style={{ marginLeft: "65%" }}>
                                                        <br /><br />
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                    );
                                }
                                else {
                                    return null;
                                }
                            })
                        }
                    </div>
                </div>
            </Box>

            <Box style={{
                marginTop: "1%",
                display: 'flex',
                alignItems: 'center',
            }}>
                <div style={{ background: "#012B31", width: "100%", height: "45%" }}>
                    <center style={{ marginTop: "-5%" }}>
                        <p style={{ color: "#fff", fontSize: 65, fontFamily: 'Montserrat, sans-serif' }}><strong>Videos</strong></p>
                    </center>
                    <div style={{ marginLeft: "5%", width: "80%", marginTop: "-8%" }} >
                        {
                            documentales.length > 0 &&
                            documentales.map((data, index) => {
                                if (count2 < 2 && data.disable === true) {
                                    count2++;
                                    return (
                                        <div>
                                            <div>
                                                <div style={{ width: "80%" }}><p style={{ color: "#fff", fontSize: 25, fontFamily: 'Montserrat, sans-serif' }}><strong>{data.title}</strong></p> </div>
                                                <div style={{ marginLeft: "10%", marginTop: "-10%", width: "100%" }}>
                                                    <BootstrapButtonver onClick={() => { setindexVideo(index); }}>Ver</BootstrapButtonver>
                                                </div>
                                                <p style={{ color: "#fff", fontSize: 15, fontFamily: 'Montserrat, sans-serif' }}>{data.context}</p>
                                                <Divider orientation="horizontal" style={{ color: "#fff", border: '1px solid' }} />
                                            </div>
                                            {
                                                count2 === 2 ?
                                                    <div style={{ marginLeft: "0%", width: "100%" }}>
                                                        <ThemeProvider theme={customTheme}>
                                                            <BootstrapButton onClick={() => { setInfModal(true); setIndexdata(2) }}>Ver todas</BootstrapButton>
                                                        </ThemeProvider>
                                                    </div> : ""}
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })
                        }
                    </div>
                </div>
                <Divider orientation="vertical" />
                {documentales.length > 0 ?
                    <iframe title="galeryYoutube" width="89%" height={595} src={"https://www.youtube.com/embed/" + documentales[indexVideo].link} frameBorder="0" allowFullScreen></iframe>
                    : ""
                }
            </Box>

            <div style={{ textAlign: "left" }}>
                <p style={{ color: "#B3865E", fontSize: 59, margin: "1%", marginLeft: "5%" }}><strong>Fotografías</strong></p>
                <ImageList
                    sx={{ width: "100%" }}
                    variant="quilted"
                    cols={3}
                    rowHeight={121}
                >
                    {

                        gallery.length > 0 ?
                            itemData.map((item) => (
                                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                    <img
                                        {...srcset(item.img, 121, item.rows, item.cols)}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            )) : ""}
                </ImageList>
            </div>
            <div className="fouter">
                <Fouter />
            </div>
            <Modal
                open={infModal}
                onClose={hiddenMedia}>
                {publucationsmodal}
            </Modal>
        </div>
    )
}
export default Product

