import React, { useEffect, useState } from 'react';
import { Box, Button, MobileStepper } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import { urlBase } from '../../controller/autentications/urlsServer'

export default function Banner(listdata) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = listdata.data;
  const maxSteps = steps.length;


  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const playProgress = (
    <Box sx={{ maxWidth: '100%' }}>
      {
        steps.length !== 0 ?
          <img width='100%' src={urlBase +"/api"+ steps[activeStep].File} alt={steps[activeStep].title} />

          : ""
      }
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button sx={{
            marginTop: '-50%',
            textTransform: 'none',
            color: '#fff',
            fontSize: '20px',
            fontFamily: 'Sofia Sans'
          }}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft sx={{ fontSize: widthResize > 750 ? '80px' : '40px' }} />
            ) : (
              <KeyboardArrowRight sx={{ fontSize: widthResize > 750 ? '80px' : '40px' }} />
            )}
          </Button>
        }

        backButton={
          <Button sx={{
            marginTop: '-50%',
            textTransform: 'none',
            color: '#fff',
            fontSize: '20px',
            fontFamily: 'Sofia Sans'
          }} onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight sx={{ fontSize: widthResize > 750 ? '80px' : '40px' }} />
            ) : (
              <KeyboardArrowLeft sx={{ fontSize: widthResize > 750 ? '80px' : '40px' }} />
            )}
          </Button>
        }
      />
    </Box>
  )

  useEffect(() => {
    window.addEventListener('resize', resize);
    const timer = setInterval(() => {
      setActiveStep(prevStep => (prevStep + 1) % maxSteps);
    }, 10000);

    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', resize);
    };
  }, [maxSteps]);
  return (
    <div>
      {playProgress}
    </div>
  );
}