import React from 'react';
import { AppBar, Box, Button, CssBaseline, IconButton, Tab, Toolbar } from '@mui/material'
import { Tabs } from '@mui/material'
import line from "../../imagenes/linea_fouter.png"
import iconoInicio from '../../imagenes/icono_inicio.png'
import { styled } from '@mui/material/styles';
function Header(index) {
  var valueIndex = index.index

  const BootstrapButton1 = styled(Button)({
    color: '#fff',
    height: "2%",
    margin: 20,
    position: "static",
    fontSize: 19,
    backgroundColor: "#1B6E81",
    textTransform: "inherit",
    cursor: 'pointer',
    fontFamily: 'Montserrat, sans-serif',
    borderRadius: 10
  });
  const BootstrapButton2 = styled(Button)({
    color: '#fff',
    height: "2%",
    margin: 20,
    position: "static",
    fontSize: 19,
    backgroundColor: "#4886C6",
    textTransform: "inherit",
    cursor: 'pointer',
    fontFamily: 'Montserrat, sans-serif',
    borderRadius: 10
  });
  const BootstrapButton3 = styled(Button)({
    color: '#fff',
    height: "2%",
    margin: 20,
    position: "static",
    fontSize: 19,
    backgroundColor: "#009940",
    textTransform: "inherit",
    cursor: 'pointer',
    fontFamily: 'Montserrat, sans-serif',
    borderRadius: 10
  });
  const BootstrapButton4 = styled(Button)({
    color: '#fff',
    margin: 20,
    position: "static",
    fontSize: 19,
    backgroundColor: "#9CC430",
    textTransform: "inherit",
    cursor: 'pointer',
    fontFamily: 'Montserrat, sans-serif',
    borderRadius: 10
  });

  return (
    <div>
      <CssBaseline />
      <Box sx={{ display: 'flex', background: '#fff' }}>
        <AppBar sx={{ height: '15%', background: '#fff', fontFamily: 'Montserrat, sans-serif', borderStyle: 'solid', borderColor: '#fff' }} component="nav">
          <img style={{ marginLeft: "-2%", marginTop: "-0.5%" }} src={line} alt="img" width={"110%"} height={30} />
          <Toolbar sx={{ marginLeft: "1%", width: "100%" }}indicator="false" >
            <IconButton href="/Menu/Inicio" >
              <img style={{ cursor: 'pointer' }} src={iconoInicio} alt='Inicio' title='Inicio' />
            </IconButton>
            <Tabs
              indicatorColor='#ffff'
              value={valueIndex}
              variant="scrollable"
              scrollButtons
              indicator="false" 
              allowScrollButtonsMobile
              sx={{
                marginLeft: 'auto'
              }}
            >
              {valueIndex === 0 ?

                <div style={{ width: "50%" , height:"50%"}}>
                    <BootstrapButton1  >Inicio</BootstrapButton1>
                  </div> :
                <Tab  indicator="false"  sx={{ color: "#012B31", fontSize: 20, textTransform: "inherit", cursor: 'pointer', fontFamily: 'Montserrat, sans-serif' }} href="/Menu/Inicio" label={"Inicio"} />
              }
              {valueIndex === 1 ?

                <div style={{ width: "50%",height:"50%"}}>
                    <BootstrapButton2  >¿Quienes somos?</BootstrapButton2>
                </div>
                :
                <Tab indicator="false"  sx={{ color: "#012B31", fontSize: 20, textTransform: "inherit", cursor: 'pointer', fontFamily: 'Montserrat, sans-serif' }} href="/Menu/Public_object" label={"¿Quienes somos?"} />
              }
              {valueIndex === 2 ?

                <div style={{ width: "50%", height:"50%" }}>
                    <BootstrapButton3  >Componentes de investigación</BootstrapButton3>
                </div> :
                <Tab indicator="false"  sx={{ color: "#012B31", fontSize: 19.5, textTransform: "inherit", cursor: 'pointer', fontFamily: 'Montserrat' }} href="/Menu/investigation" label={"Componentes de investigación"} ></Tab>
              }
              {valueIndex === 3 ?
                <div style={{ width: "50%" }}>
                  <BootstrapButton4 >Productos</BootstrapButton4>
                </div>
                :
                <Tab indicator="false"  sx={{ color: "#012B31", fontSize: 20, textTransform: "inherit", cursor: 'pointer', fontFamily: 'Montserrat, sans-serif' }} href="/Menu/Product" label={"Productos"} />
              }
            </Tabs>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
export default Header;
