import Inition from '../../views/initions';
import Investigation from '../../views/investigation';
import Products from '../../views/products';
import Publicobject from '../../views/public_object';
import CMSAdmin from '../../views/CMS/indexCms'
import CMSAdminLogin from '../../views/CMS/Login'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function RouterDomin() {
  return (
    <Router>
      <Routes>
        <Route path="/Menu/investigation" element={<Investigation />} />
        <Route path="/Menu/Product" element={<Products />} />
        <Route path="/Menu/Public_object" element={<Publicobject />} />
        <Route path="/Menu/Inicio" element={<Inition />} />
        <Route path="/" element={<Inition />} />
        <Route path="/Soucer/admin/login" exact element={<CMSAdminLogin />}/>
        <Route path="/Menu/CMS/admin" exact element={<CMSAdmin />}/>
      </Routes>
    </Router>
  );
}
export default RouterDomin;
