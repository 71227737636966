import React, { useState } from 'react';
import { Button, Container } from '@mui/material';
import './stylesCms.css'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { apiUser, compUrlsadmin } from '../../controller/autentications/urlsServer';
import axios from 'axios';
import logo from '../../imagenes/inicio.png'
import login_ from '../../imagenes/cosechalogin.png'
import Cookies from 'universal-cookie';
import Fouter from "../foouter"
import line from "../../imagenes/linea_fouter.png"
const cookies = new Cookies();

export default function Login() {
  
  cookies.set('disableAdmin', false, { path: "/" });
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({ 'contraseña': '', 'user': '' })

  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }

  const restData = () => {
    setConsolaSeleccionada({ 'contraseña': '', 'user': '' });
  }

  const getUser = async () => {
    await axios.get(apiUser + consolaSeleccionada.user + '/' + consolaSeleccionada.contraseña, compUrlsadmin)
      .then(response => {
        if (response.status === 200 && response.data.length !== 0) {
          cookies.set('disableAdmin', true, { path: "/" });
          validacionUser();
        }
        else {
          restData();
          cookies.set('disableAdmin', false, { path: "/" });
          alert('Credenciales incorrectas')
        }
      })
      .catch(error => {
        restData();
        alert('Credenciales incorrectas')
      })
  }

  const validacionUser = () => {
    window.location.href = "/Menu/CMS/admin"
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Montserrat',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#1B6E81',
      },
    },
  });


  return (
    <>
    <div style={{ fontFamily: 'Montserrat' }}>
    <img style={{ marginLeft: "-2%" }} src={line} alt="img" width={"110%"} height={30} />
       
      <div style={{ display: 'flex', flexDirection:'column' }}>
        <img style={{marginTop:"2%" }}alt="inicio" src={login_} width={600} />
      </div>

      <div style={{marginLeft:"28%",marginTop:"-25%", width:"70%"}}>
        <div align='center' style={{marginLeft:"20%",marginTop:"-25%"}}>
          <img style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "/Menu/Inicio" }} className='imag' alt="inicio" src={logo} width={400} />
        </div>

        <Container>
          <div style={{ marginLeft: '30%' }}>
            <div>
              <font position='start' size="4" color="#1B6E81"> Nombre de usuario </font><br />
              <input className='inputs' type='text' variant="standard" name="user" required  value={consolaSeleccionada && consolaSeleccionada.user} onChange={handleChangeRegister} />
            </div>
            <div>
              <font position='start' size="4" color="#1B6E81"> Contraseña</font><br />
              <input className='inputs' type='password' variant="standard" name="contraseña" required  value={consolaSeleccionada && consolaSeleccionada.contraseña} onChange={handleChangeRegister} />
            </div>
          </div>
        </Container><br />
        <Container >
          <div align="center">
            <ThemeProvider theme={customTheme}>
              <BootstrapButton onClick={getUser} variant="contained">Iniciar sesión</BootstrapButton>
            </ThemeProvider>
          </div>
        </Container>
      </div>
    </div>
    <br/><br/><br/>

      <div className="fouter">
        <Fouter />
      </div>
      </>
  );
}
