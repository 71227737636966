import { useEffect, useState } from "react"
import "./style.css"
import Fouter from "../foouter"
import Headers from "../headers"
import { Box, Button, Divider, Modal } from "@mui/material"
import calendarioCasac from "../../imagenes/calendarioCasac.png"
import submenu1 from "../../imagenes/submenu1.png"
import submenu2 from "../../imagenes/submenu2.png"
import submenu3 from "../../imagenes/submenu3.png"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { apiEventsall, apiMeetingall, apiReleasesall, apiInitionall, apiGallery, urlBase, compUrls } from "../../controller/autentications/urlsServer"
import axios from 'axios';
import ModalInfo from '../componet/modalinfo'

import Banner from '../componet/banner';

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}
const BootstrapButton = styled(Button)({
    color: '#fff',
    height: "2%",
    margin: 20,
    position: "static",
    fontSize: 25,
    backgroundColor: "#1B6E81",
    textTransform: "inherit",
    cursor: 'pointer',
    fontFamily: 'Montserrat',
    borderRadius: 17
});

const customTheme = createTheme({
    palette: {

        primary: {
            main: '#1B6E81',
        },
    },
});

const Initions = () => {
    const [statusbuttonA, setStatusbuttonA] = useState(1)
    const [statusbuttonB, setStatusbuttonB] = useState(0)
    const [statusbuttonC, setStatusbuttonC] = useState(0)
    const [publication, setpublication] = useState([]);
    const [banners, setbanners] = useState([]);
    const [infModal, setInfModal] = useState(false);
    const [indexdata, setIndexdata] = useState(0);
    const [gallery, setGallery] = useState([]);

    const itemButton = (item) => {
        if (item === 1) {
            setStatusbuttonA(item)
            setStatusbuttonB(0)
            setStatusbuttonC(0)
        }
        if (item === 2) {
            setStatusbuttonA(0)
            setStatusbuttonB(item)
            setStatusbuttonC(0)

        } if (item === 3) {
            setStatusbuttonA(0)
            setStatusbuttonB(0)
            setStatusbuttonC(item)
        }
    }

    const GetPublications = async (index) => {
        await axios.get(index === 3 ? apiMeetingall : index === 2 ? apiReleasesall : apiEventsall, compUrls)
            .then(response => {
                setpublication(response.data.reverse());
            });
    }

    const getBanner = async () => {
        await axios.get(apiInitionall, compUrls)
            .then(response => {
                setbanners(response.data);
            });
    }

    const getGallery = async () => {
        await axios.get(apiGallery + "Inicio", compUrls)
            .then(response => {
                setGallery(response.data);
            });
    }

    const hiddenMedia = () => {
        setInfModal(!infModal);

    }

    const publucationsmodal = (
        <div style={{ width: "50%" }}>
            <ModalInfo datalits={publication} item={indexdata} />
        </div>
    )

    const itemData2 = gallery.length > 0 ? [
        {

            img: urlBase +"/api"+ gallery[0].File,
            title: gallery[0].title,
            rows: 3,

        },
        {
            img: urlBase +"/api"+ gallery[1].File,
            title: gallery[1].title,
            rows: 3,

        },
        {
            img: urlBase +"/api"+ gallery[2].File,
            title: gallery[2].title,
            rows: 6,
            cols: 1,

        }, {
            img: urlBase +"/api"+ gallery[3].File,
            title: gallery[3].title,
            rows: 3,
            cols: 2,
        }

    ] : null;

    useEffect(() => {
        getBanner();
        GetPublications(1);
        getGallery();
    }, []);
    let count = 0;
    return (
        <div className="conteiner">
            <div style={{ position: "absolute" }}>
                <Headers index={0} />
            </div>
            <div style={{
                marginTop: "8%"
            }}>
                <Banner data={banners} />
                <Box style={{
                    marginTop: "2%",
                    display: 'flex',
                    alignItems: 'center',
                    width: "100%"

                }}>
                    <img style={{ marginLeft: "10%" }} src={calendarioCasac} alt="Descrube_casa" />
                    <Divider orientation="vertical" />
                    <div style={{ textAlign: "left", marginLeft: "5%", background: "#ffff", width: "50%" }}>

                        <Box sx={{
                            marginLeft: "1",
                            display: 'flex',
                            '& > *': {
                                m: 2.8,
                            }
                        }}>
                            {statusbuttonA === 0 ?
                                <Button onClick={() => { itemButton(1); setIndexdata(3); GetPublications(1) }} sx={{ margin: 2, position: "static", color: "#1B6E81", fontSize: 25, textTransform: "inherit", fontFamily: 'Montserrat' }}>Eventos</Button> :
                                <ThemeProvider theme={customTheme}>
                                    <BootstrapButton style={{ background: "1B6E81" }} >Eventos</BootstrapButton>
                                </ThemeProvider>
                            }
                            {statusbuttonB === 0 ?
                                <Button onClick={() => { itemButton(2); setIndexdata(4); GetPublications(2) }} sx={{ margin: 2, position: "static", color: "#1B6E81", fontSize: 25, textTransform: "inherit", cursor: 'pointer', fontFamily: 'Montserrat' }}>Comunicados</Button> :
                                <ThemeProvider theme={customTheme}>
                                    <BootstrapButton>Comunicados</BootstrapButton>
                                </ThemeProvider>
                            }
                            {statusbuttonC === 0 ?
                                <Button onClick={() => { itemButton(3); setIndexdata(5); GetPublications(3) }} sx={{ margin: 2, position: "static", color: "#1B6E81", fontSize: 25, textTransform: "inherit", cursor: 'pointer', fontFamily: 'Montserrat' }}>Reuniones</Button> :
                                <ThemeProvider theme={customTheme}>
                                    <BootstrapButton>Reuniones</BootstrapButton>
                                </ThemeProvider>
                            }
                        </Box>
                        {
                            publication.length > 0 &&
                            publication.map((data, index) => {
                                if (count < 2 && data.disable === true) {
                                    count++;
                                    return (
                                        <div>
                                            <div style={{ marginLeft: "5%", width: "80%" }} >
                                                <p key={index} style={{ fontWeight: "bold", fontSize: 20, color: "#012B31", fontFamily: 'Montserrat' }}>{data.title}</p>
                                                <p key={index + 1} style={{ fontSize: 20, color: "#000000", fontFamily: 'Montserrat' }}>{data.context}</p>
                                                <br />

                                            </div>
                                            {count === 2 ?
                                                <div style={{ marginLeft: "0%", width: "100%" }}>
                                                    <ThemeProvider theme={customTheme}>
                                                        <BootstrapButton onClick={() => { setInfModal(true); }}>Ver todas</BootstrapButton>
                                                    </ThemeProvider>
                                                </div> : ""}
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })
                        }
                    </div>
                </Box>
                <br />
                <Box style={{ background: "#1B6E81" }}>
                    <div style={{ textAlign: "center", height: 120 }}>
                        <p style={{ fontFamily: 'Montserrat', fontSize: 69, fontWeight: "bold", marginLeft: "5%", color: "#ffff" }}>Investigación</p>
                    </div>
                    <center>
                        <ImageList

                            sx={{
                                width: "90%", '& > *': {
                                    m: 2.5,
                                }
                            }}
                            variant="quilted"
                            cols={3}
                            rowHeight={121}

                        >
                            {itemData1.map((item) => (
                                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                    <img
                                        {...srcset(item.img, 121, item.rows, item.cols)}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                        <br />
                    </center>
                </Box>
                <br />
                <div>
                    <center>
                        <h1 style={{ fontFamily: 'Montserrat',color: "#012B31" }}>Multimedia</h1>
                    </center>
                    <ImageList

                        sx={{ width: "100%" }}
                        variant="quilted"
                        cols={3}
                        rowHeight={121}
                    >
                        {
                            gallery.length > 0 ?
                                itemData2.map((item) => (
                                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                        <img
                                            {...srcset(item.img, 121, item.rows, item.cols)}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                )) : ""
                        }
                    </ImageList>
                </div>
                <div >
                    <Fouter />
                </div>
            </div>
            <Modal
                open={infModal}
                onClose={hiddenMedia}>
                {publucationsmodal}
            </Modal>

        </div>
    )
}
export default Initions

const itemData1 = [

    {
        img: submenu1,
        title: 'Camera',
        rows: 4,

    },
    {
        img: submenu2,
        title: 'Burger',
        rows: 4,

    },
    {
        img: submenu3,
        title: 'Camera',
        rows: 4,

    }
];

