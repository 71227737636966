const urlBase_ = 'http://82.180.132.137:8000'
// const urlBase_ = 'http://127.0.0.1:8000'


export const urlBase = urlBase_

export const apiUser = urlBase_ + '/api/user/login/'
export const apiUserUp = urlBase_ + '/api/user/userAdminUp/'
export const apiEvents = urlBase_ + '/api/event'
export const apiReleases = urlBase_ + '/api/releases'
export const apiMeeting = urlBase_ + '/api/meeting'
export const apiBitacora = urlBase_ + '/api/bitacoras'
export const apiDocumentary = urlBase_ + '/api/documentary'
export const apiMaps = urlBase_ + '/api/mapsBanner'
export const apiInition = urlBase_ + '/api/initionsBanner'
export const apiInvestigation = urlBase_ + '/api/investigationBanner'
export const apiPathVideo = urlBase_ + '/api/pathVideo'
export const apiGallery = urlBase_ + '/api/galleryType/type/'



export const apiEventsall = urlBase_ + '/api/event'
export const apiReleasesall = urlBase_ + '/api/releases'
export const apiMeetingall = urlBase_ + '/api/meeting'
export const apiBitacoraall = urlBase_ + '/api/bitacorasall'
export const apiDocumentaryall = urlBase_ + '/api/documentaryall'
export const apiMapsall = urlBase_ + '/api/mapsBannerall'
export const apiInitionall = urlBase_ + '/api/initionsBannerall'
export const apiInvestigationall = urlBase_ + '/api/investigationBannerall'
export const apiPathVideoall = urlBase_ + '/api/pathVideo'
export const apiGalleryall = urlBase_ + '/api/gallery'



export const apiEventsUp = urlBase_ + '/api/eventUp/id/'
export const apiReleasesUp = urlBase_ + '/api/releasesUp/id/'
export const apiMeetingUp = urlBase_ + '/api/meetingUp/id/'
export const apiBitacoraUp = urlBase_ + '/api/bitacorasUp/id/'
export const apiDocumentaryUp = urlBase_ + '/api/documentaryUp/id/'
export const apiMapsUp = urlBase_ + '/api/mapsBannerUp/id/'
export const apiInitionUp = urlBase_ + '/api/initionsBannerUp/id/'
export const apiInvestigationUp = urlBase_ + '/api/investigationBannerUp/id/'
export const apiPathVideoUp = urlBase_ + '/api/pathVideoUp/id/'
export const apiGalleryUp = urlBase_ + '/api/galleryUp/id/'

export const compUrls = {
  headers: {
    'authorization': 'token 830621fa493ba14e712dca559ace785d08124df5',
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}

export const compUrlsfile = {
  headers: {
    'content-type': 'multipart/form-data',
    'authorization': 'token 830621fa493ba14e712dca559ace785d08124df5'
  }
}

export const compUrlsadmin = {
  headers: {
    'authorization': 'token 830621fa493ba14e712dca559ace785d08124df5',
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}