import React, { useState } from 'react';
import { Button, Checkbox, CircularProgress, IconButton } from '@mui/material';
import {
  apiInition,
  apiInvestigation,
  apiBitacora,
  apiDocumentary,
  apiEvents,
  apiGalleryall,
  apiMaps,
  apiMeeting,
  apiPathVideo,
  apiReleases,
  compUrls, compUrlsfile
} from '../../controller/autentications/urlsServer.js';
import MmsIcon from '@mui/icons-material/Mms';
import './stylesCms.css'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import axios from 'axios';

export default function Visualizador(data) {
  const selection = data.rowId


  const [estadoConsulta, setEstadoConsulta] = useState(false)
  const [consolaSeleccionada, setConsolaSeleccionada] = useState(selection)
  const typeGallery = ['Seleccione el tipo de galeria','Inicio', 'Quienes somos', 'producto']
  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }

  const handleChangeCheck = e => {
    const { name, checked } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: checked,
    }
    ))
  }

  const handleChangeRegisterfile = (e) => {
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [e.target.name]: e.target.files[0]
    }

    ))
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Montserrat',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });


  // const PutLink = async () => {
  //   await axios.put(
  //     data.type === "Banner initions"?apiInitionUp+ consolaSeleccionada.id:
  //     data.type === "Gallerys"? apiGalleryUp+ consolaSeleccionada.id:
  //     data.type === "Banner maps"? apiMapsUp+ consolaSeleccionada.id:
  //     data.type === "Path videos"? apiPathVideoUp+ consolaSeleccionada.id:
  //     data.type === "Bannerinvestigations"? apiInvestigationUp+ consolaSeleccionada.id:
  //     data.type === "Documentarys"? apiDocumentaryUp+ consolaSeleccionada.id:
  //     data.type === "Bitacoras"? apiBitacoraUp+ consolaSeleccionada.id:
  //     data.type === "Meetings"? apiMeetingUp+ consolaSeleccionada.id:
  //     data.type === "Releasess"? apiReleasesUp+ consolaSeleccionada.id:
  //     data.type === "Meeting"? apiMeetingUp+ consolaSeleccionada.id:
  //     apiEventsUp+ consolaSeleccionada.id,
  //      consolaSeleccionada, compUrls)
  //     .then(response => {
  //       setEstadoConsulta(false)
  //       alert('Se registro correctamente, actualiza la tabla')
  //     });
  // }

  const PostLink = async () => {
    await axios.post(
      data.type === "Banner initions" ? apiInition :
        data.type === "Gallerys" ? apiGalleryall :
          data.type === "Banner maps" ? apiMaps :
            data.type === "Path videos" ? apiPathVideo :
              data.type === "Bannerinvestigations" ? apiInvestigation :
                data.type === "Documentarys" ? apiDocumentary :
                  data.type === "Bitacoras" ? apiBitacora :
                    data.type === "Meetings" ? apiMeeting :
                      data.type === "Releasess" ? apiReleases :
                        data.type === "Meeting" ? apiMeeting :
                          apiEvents
      , consolaSeleccionada
      // , compUrls
      , data.type === "Banner initions" ||
        data.type === "Gallerys" ||
        data.type === "Bannerinvestigations" ||
        data.type === "Banner maps" ||
        data.type === "Documentarys" ||
        data.type === "Bitacoras"
        ? compUrlsfile : compUrls

    )
      .then(response => {
        setEstadoConsulta(false)
        alert('Se registro correctamente, actualiza la tabla')
      });
  }


  return (
    <div style={{ fontFamily: 'Montserrat', marginLeft: "2%" }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {estadoConsulta === true ?
          <div style={{ position: 'absolute', marginLeft: '47%', marginTop: '40%' }} >
            <CircularProgress size={70} />
          </div> : ""}

        <div style={{ margin: "1%", width: "100%" }}>
          <div>
            <font position='start' size="4" color="#3A2872"> Titulo </font><br />
            <input className='inputs' type='text' style={{ width: "50%" }} variant="standard" name="title" required label='title' value={consolaSeleccionada && consolaSeleccionada.title} onChange={handleChangeRegister} />
          </div>
          <br />
          {data.type === "Banner initions" || data.type === "Gallerys" || data.type === "Banner maps" || data.type === "Path videos" || data.type === "Bannerinvestigations" ? "" :
            <div>
              <font position='start' size="4" color="#3A2872"> Descripcion </font><br />
              <input className='inputs' type='text' style={{ width: "50%" }} variant="standard" name="context" required label='context' value={consolaSeleccionada && consolaSeleccionada.context} onChange={handleChangeRegister} />
            </div>}
          <br />
          {data.type === "Gallerys" ?
            <div>
              <font position='start' size="4" color="#3A2872"> Tipo de galeria</font><br />
              <select
                id='typeGallery'
                className='inputs'
                name='typeGallery'
                defaultValue={consolaSeleccionada.typeGallery}
                style={{ width: '50%', fontFamily: 'Montserrat' }}
                onChange={handleChangeRegister}
              >
                {typeGallery.map((options, i) => (
                  <option
                    disabled={options === 'Seleccione el tipo de galeria' ? true : false}
                    key={i} value={options}>
                    {options}
                  </option>
                ))}
              </select>
            </div>
            : ""
          }  <br />
          <div >
            {data.type === "Banner maps" || data.type === "Banner initions" || data.type === "Bannerinvestigations" || data.type === "Gallerys" || data.type === "Documentarys" || data.type === "Bitacoras" ?
              <div>
                <font position='start' size="4" color="#3A2872"> Subir Archivo </font><br />
                <IconButton component="label">
                  <input onChange={handleChangeRegisterfile} name='File' accept="image/x-png,image/gif,image/jpeg,application/pdf,.doc,.docx" hidden type="file" />
                  <MmsIcon sx={{ fontSize: '50px' }} />
                </IconButton>
              </div> : ""}
            {data.type === "Bannerinvestigations" ?
              <div>
                <font position='start' size="4" color="#3A2872"> Subir Archivo informativo </font><br />
                <IconButton component="label">
                  <input onChange={handleChangeRegisterfile} name='Fileinfo' accept="image/x-png,image/gif,image/jpeg" hidden type="file" />
                  <MmsIcon sx={{ fontSize: '50px' }} />
                </IconButton>
              </div> : ""}
              {data.type === "Bannerinvestigations" ?
              <div>
                <font position='start' size="4" color="#3A2872"> Subir Archivo datos informativos </font><br />
                <IconButton component="label">
                  <input onChange={handleChangeRegisterfile} name='FileDatainfo' accept="image/x-png,image/gif,image/jpeg" hidden type="file" />
                  <MmsIcon sx={{ fontSize: '50px' }} />
                </IconButton>
              </div> : ""}

            {data.type === "Gallerys" || data.type === "Banner maps" || data.type === "Banner initions" || data.type === "Bannerinvestigations" ? "" :
              <div>
                <p>Disponible al publico.</p>
                <Checkbox
                  onChange={handleChangeCheck}
                  name='disable'
                  checked={consolaSeleccionada.disable}
                  label="Disponible al publico."
                />
              </div>
            }
          </div>

        </div>
        <br />
        {data.type === "Path videos" || data.type === "Documentarys" ?
          <div>
            <font position='start' size="4" color="#3A2872"> Codigo de video de YOUTUBE (el Codigo es el que va despues del v=) </font><br />
            <input className='inputs' type='text' variant="standard" name="link" required label='link' value={consolaSeleccionada && consolaSeleccionada.link} onChange={handleChangeRegister} />
          </div> : ""
        }
        <br />
        <br />
        <div align="center">
          <ThemeProvider theme={customTheme}>
            {data.new === true ?

              // <BootstrapButton disabled={estadoConsulta} onClick={() => { PutLink(); setEstadoConsulta(true) }} variant="contained">Guardar</BootstrapButton> :
              <BootstrapButton disabled={estadoConsulta} onClick={() => { PostLink(); setEstadoConsulta(true) }} variant="contained">Guardar</BootstrapButton> : ""}
          </ThemeProvider>
        </div>
        <br />
      </div>
    </div>
  );
}
