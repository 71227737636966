import React, { useEffect, useState } from 'react';
import { Button, Container, Box, ButtonGroup, Card, IconButton, Modal, CircularProgress } from '@mui/material';
import './stylesCms.css'
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import VisualizadorUpdate from './VisualizadorUpdate.js'
import { apiUserUp, apiInitionall, apiInvestigationall, apiBitacoraall, apiDocumentaryall, apiEventsall, apiGalleryall, apiMapsall, apiMeetingall, apiPathVideoall, apiReleasesall, compUrlsadmin } from '../../controller/autentications/urlsServer.js';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import PodcastActions from './Actions.js';
import Cookies from 'universal-cookie';

const componentDidMount = () => {
  if ((cookies.get('disableAdmin')) === false) {
    window.location.href = "/Soucer/admin/login";
  }
}
const cookies = new Cookies();

export default function Cms() {

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({ 'user': '', 'contraseña': '', 'confirmation': '', 'contraseñanueva': '' });
  const [estadoConsulta, setEstadoConsulta] = useState(false)

  // Bannerinvestigations Path videos
  const [type, setType] = useState('')
  const [data, setData] = useState([])
  const [actions, setActions] = useState(false)
  const [usermodal, setUsermodal] = useState(false)

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'title',
      headerName: 'Titulo',
      width: 220,
    },
    {
      field: 'context',
      headerName: 'Descripción',
      width: 150,
    },
    {
      field: 'disable',
      headerName: 'Habilitado',
      width: 150,
    },
    {
      field: 'date_publications',
      headerName: 'Fecha publicación',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsVideos = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'title',
      headerName: 'Titulo',
      width: 220,
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 150,
    },
    {
      field: 'disable',
      headerName: 'Habilitado',
      width: 150,
    },
    {
      field: 'date_publications',
      headerName: 'Fecha publicación',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsbanner = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'title',
      headerName: 'Titulo',
      width: 220,
    },
    {
      field: 'File',
      headerName: 'Archivo',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsbannerInfo = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'title',
      headerName: 'Titulo',
      width: 220,
    },
    {
      field: 'File',
      headerName: 'Archivo1',
      width: 150,
    },
    {
      field: 'Fileinfo',
      headerName: 'Archivo2',
      width: 150,
    },
    {
      field: 'FileDatainfo',
      headerName: 'Archivo2',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsGallery = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'title',
      headerName: 'Titulo',
      width: 220,
    },
    {
      field: 'File',
      headerName: 'Archivo',
      width: 150,
    },
    {
      field: 'TypeGallery',
      headerName: 'Tipo',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsProductos = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'title',
      headerName: 'Titulo',
      width: 220,
    },
    {
      field: 'context',
      headerName: 'Descripción',
      width: 150,
    },
    {
      field: 'disable',
      headerName: 'Habilitado',
      width: 150,
    },
    {
      field: 'date_publications',
      headerName: 'Fecha de publicación',
      width: 150,
    },
    {
      field: 'PublicationsFile',
      headerName: 'Archivo',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]

  var dataLink = type === "Meetings" || type === "Releasess" || type === "Events" ?
    {
      'title': "",
      'context': "",
      'disable': true
    }

    : type === "Bitacoras" || type === "Documentarys" ?
      {
        'title': "",
        'context': "",
        'disable': true,
        'PublicationsFile': null
      } :

      type === "Path videos" ?
        {
          'title': "",
          'link': "",
          'disable': true,
        } :
        type === "Gallerys" ?
        {
          'title': "",
          'File':null,
          'typeGallery': "Seleccione el tipo de galeria",
        } :

        type === "Banner initions" || type === "Banner maps" ?
          {
            'title': "",
            'File': ""
          } : {
            'title': "",
            'File': "",
            'Fileinfo': "",
            'FileDatainfo': "",
          }
  const abrirCerraractions = () => {
    setActions(!actions)
  }

  const abrirCerrarUser = () => {
    setUsermodal(!usermodal);
    resetuser();
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 15,
    lineHeight: 1.5,
    borderRadius: '15px',
    color: '#fff',
    fontFamily: [
      'Montserrat',
    ].join(','),
  });

  const BootstrapButton2 = styled(Button)({
    textTransform: 'none',
    fontSize: 20,
    lineHeight: 1.5,
    fontFamily: [
      'Montserrat',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#1B6E81',
      },
    },
  });

  const GetBannerInitions = async () => {
    setType("Banner initions")
    await axios.get(apiInitionall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetBannerinvestigations = async () => {
    setType("Bannerinvestigations")
    await axios.get(apiInvestigationall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetBannerMaps = async () => {
    setType("Banner maps")
    await axios.get(apiMapsall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });

  }

  const GetDocumentaryUp = async () => {
    setType("Documentarys")
    await axios.get(apiDocumentaryall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetEvents = async () => {
    setType("Events")
    await axios.get(apiEventsall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetBitacoras = async () => {
    setType("Bitacoras")
    await axios.get(apiBitacoraall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false)
      });
  }

  const GetGallery = async () => {
    setType("Gallerys")
    await axios.get(apiGalleryall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetReleasess = async () => {
    setType("Releasess")
    await axios.get(apiReleasesall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetPathVideos = async () => {
    setType("Path videos")
    await axios.get(apiPathVideoall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetMeetings = async () => {
    setType("Meetings")
    await axios.get(apiMeetingall, compUrlsadmin)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }



  const resetuser = () => {
    setConsolaSeleccionada({
      'user': '',
      'contraseña': '',
      'confirmation': '',
      'contraseñanueva': ''
    })
  }

  const useruppassword = () => {
    var datosUp = {
      'user': 'adminCiencia&esencia',
      'contraseña': consolaSeleccionada.confirmation
    }
    axios.put(apiUserUp + consolaSeleccionada.contraseña, datosUp, compUrlsadmin)
      .then(response => {
        abrirCerrarUser()
        alert('Se actualizo correctamente')
        setEstadoConsulta(false);
      })
      .catch(error => {
        alert('Error intenta nuevamente')
        setEstadoConsulta(false);
      })
  }


  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value
    }
    ))
  }
  const userUP = (
    <Card sx={{
      borderRadius: 5,
      width: '50%',
      overflowY: 'auto',
      height: '65%',
      backgroundColor: "#fff",
      position: 'absolute',
      top: '20%',
      left: '25%'
    }}>
      <div align='end'>
        <IconButton onClick={() => { abrirCerrarUser() }}>
          <CloseIcon sx={{ fontSize: 35, }} />
        </IconButton>
      </div>
      <div style={{ fontFamily: 'Montserrat' }}>
        <Container>


          <div align='center'>
            <font position='start' size="4" color="#3A2872"><strong> ¿Deseas cambiar la contraseña de acceso? </strong> </font><br />

            <font position='start' size="4" color="#3A2872"> Ingresa la informacion en los campos</font><br />
            <div style={{ width: '50%' }}>
              <br />
              <font position='start' size="4" color="#3A2872">Contraseña actuar*</font>
              <input type='password' name="contraseña" className="inputMateriallistcountry" value={consolaSeleccionada && consolaSeleccionada.contraseña} onChange={handleChangeRegister} />
            </div>
            <br />
            <div style={{ width: '50%' }}>
              <font position='start' size="4" color="#3A2872">Nueva contraseña</font>
              <input type='password' name="contraseñanueva" className="inputMateriallistcountry" value={consolaSeleccionada && consolaSeleccionada.contraseñanueva} onChange={handleChangeRegister} />
            </div>
            <br />
            <div style={{ width: '50%' }}>
              <font position='start' size="4" color="#3A2872">Confirme nueva contraseña*</font>
              <input type='password' name="confirmation" className="inputMateriallistcountry" value={consolaSeleccionada && consolaSeleccionada.confirmation} onChange={handleChangeRegister} />
              {consolaSeleccionada.confirmation !== consolaSeleccionada.contraseñanueva && consolaSeleccionada.confirmation !== '' ?
                <font position='start' size="4" color="red">Contraseñas no son iguales</font> : ""}

              <br />
            </div>
          </div>
          <br />
          <div align="center" >
            <ThemeProvider theme={customTheme}>
              <BootstrapButton disabled={consolaSeleccionada.confirmation === consolaSeleccionada.contraseñanueva && consolaSeleccionada.contraseñanueva !== '' ? false : true} onClick={() => { useruppassword() }} variant="contained">Confirmar</BootstrapButton>
              <BootstrapButton sx={{ marginLeft: '5%' }} onClick={() => { abrirCerrarUser() }} variant="contained">Cancelar</BootstrapButton>
            </ThemeProvider>
          </div>
        </Container>
      </div>

    </Card>
  )

  const actionsdata = (
    <Card sx={{
      borderRadius: 5,
      width: '80%',
      overflowY: 'auto',
      height: '80%',
      backgroundColor: "#fff",
      position: 'absolute',
      top: '10%',
      left: '5%'
    }}>
      <div align='end'>
        <IconButton onClick={() => { setActions(!actions) }}>
          <CloseIcon sx={{ fontSize: 35, }} />
        </IconButton>
      </div>
      <div>
        <VisualizadorUpdate rowId={dataLink} type={type} new={true} />
      </div>
    </Card>
  )

  const closeEntry = () => {
    window.location.href = "/Soucer/admin/login";
    cookies.set('disableAdmin', false, { path: "/" });
  }

  useEffect(() => {
    componentDidMount();
  }, []);
  return (
    <div>
      <div align="center">
      </div>
      <div>
        {estadoConsulta === true ?
          <div style={{ position: 'absolute', marginLeft: '47%', marginTop: '40%' }} >
            <CircularProgress size={70} />
          </div> : ""}

        <Container>
          <br />
          <div style={{ width: "80%" }}>
            <font className='titleSus' size="7" color="#4EBBB7">¡Hola Admin Aquí, podrás actualizar y agregar el contenido de la página! ¡Todo en solo sitio! </font><br />
          </div>
          <Box >
            <div>
              <ThemeProvider theme={customTheme}>
                <BootstrapButton onClick={() => { closeEntry() }} sx={{ marginLeft: '90%', marginTop: "-20%", width: '15%', fontSize: '100%' }} variant="contained">Salir</BootstrapButton>
                <BootstrapButton onClick={() => { setUsermodal(!usermodal) }} sx={{ marginLeft: '90%', marginTop: "-13%", width: '15%', fontSize: '100%' }} variant="contained">Cambiar contraseña</BootstrapButton>
                <ButtonGroup variant="text" >
                  <BootstrapButton2 sx={{ background: type === "Banner initions" ? '#1B6E81' : '#fff', color: type === "Banner initions" ? '#fff' : '#1B6E81', }} onClick={() => { setEstadoConsulta(true); GetBannerInitions() }}>Banner Inicio</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Banner maps" ? '#1B6E81' : '#fff', color: type === "Banner maps" ? '#fff' : '#1B6E81' }} onClick={() => { setEstadoConsulta(true); GetBannerMaps() }}>Banner mapas</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Bannerinvestigations" ? '#1B6E81' : '#fff', color: type === "Bannerinvestigations" ? '#fff' : '#1B6E81' }} onClick={() => { setEstadoConsulta(true); GetBannerinvestigations() }}>Banner investigacion</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Bitacoras" ? '#1B6E81' : '#fff', color: type === "Bitacoras" ? '#fff' : '#1B6E81' }} onClick={() => { setEstadoConsulta(true); GetBitacoras() }}>Bitacoras</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Documentarys" ? '#1B6E81' : '#fff', color: type === "Documentarys" ? '#fff' : '#1B6E81' }} onClick={() => { setEstadoConsulta(true); GetDocumentaryUp() }}>Documentales</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Events" ? '#1B6E81' : '#fff', color: type === "Events" ? '#fff' : '#1B6E81' }} onClick={() => { setEstadoConsulta(true); GetEvents() }}>Eventos</BootstrapButton2>
                </ButtonGroup>
                <br />
                <ButtonGroup variant="text" >
                  <BootstrapButton2 sx={{ background: type === "Gallerys" ? '#1B6E81' : '#fff', color: type === "Gallerys" ? '#fff' : '#1B6E81', }} onClick={() => { setEstadoConsulta(true); GetGallery() }}>Galeria</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Meetings" ? '#1B6E81' : '#fff', color: type === "Meetings" ? '#fff' : '#1B6E81' }} onClick={() => { setEstadoConsulta(true); GetMeetings() }}>Reuniones</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Path videos" ? '#1B6E81' : '#fff', color: type === "Path videos" ? '#fff' : '#1B6E81' }} onClick={() => { setEstadoConsulta(true); GetPathVideos() }}>Videos</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Releasess" ? '#1B6E81' : '#fff', color: type === "Releasess" ? '#fff' : '#1B6E81' }} onClick={() => { setEstadoConsulta(true); GetReleasess() }}>Comunicados</BootstrapButton2>
                </ButtonGroup>
              </ThemeProvider>
            </div>
          </Box>

          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={data}
              columns={
                type === "Banner initions" || type === "Banner maps" ? columnsbanner :
                  type === "Bannerinvestigations" ? columnsbannerInfo :
                    type === "Path videos" ? columnsVideos :
                      type === "Meetings" || type === "Releasess" || type === "Events" ? columns :
                        type === "Documentarys" || type === "Bitacoras" ? columnsProductos : columnsGallery
              }

              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 6,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </Box>
          <div align='center' style={{ marginTop: '-4%', marginRight: '10%' }}>
            {type !== 'Comentarios' && type !== 'TopPodcast' && type !== 'material' && type !== '' && type !== 'Proyectos' ?
              <ThemeProvider theme={customTheme}>
                <BootstrapButton onClick={() => setActions(!actions)} sx={{ marginLeft: '8.5%' }} variant="contained">Agregar {type} </BootstrapButton>
              </ThemeProvider> : ""}
          </div>
        </Container>
        <br /><br />
      </div>
      <Modal
        open={actions}
        onClose={abrirCerraractions}>
        {actionsdata}
      </Modal>

      <Modal
        open={usermodal}
        onClose={abrirCerrarUser}>
        {userUP}
      </Modal>
    </div>
  );
}
