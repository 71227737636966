import * as React from 'react';
import { useEffect, useState } from "react"
import "./style.css"
import Fouter from "../foouter"
import Headers from "../headers"
import { apiInvestigationall, urlBase, compUrls } from "../../controller/autentications/urlsServer"
import axios from 'axios';
import { Box, Button, Collapse, FormControlLabel } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


const Investigations = () => {
    const [banners, setbanners] = useState([]);
    const [indexInfo, setIndexInfo] = useState(0)
    const [indexImagA, setIndexImagA] = useState(0)
    const [indexImagB, setIndexImagB] = useState(0)
    const [indexImagC, setIndexImagC] = useState(0)
    const [checked, setChecked] = useState(false);
    const [widthResize, setWidthResize] = useState(window.innerWidth)

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const getBanner = async () => {
        await axios.get(apiInvestigationall, compUrls)
            .then(response => {
                setbanners(response.data);
            });
    }
    const resize = () => {
        setWidthResize(window.innerWidth)
    }



    const bannerinfo = (
        <Box sx={{ marginTop: "-40%", zIndex: 1, position: "absolute" }}>
            <FormControlLabel
                sx={{ position: 'absolute' }}
                control={
                    <Button sx={{
                        marginTop: widthResize > 1400 ? "200%" : "130%",
                        textTransform: 'none',
                        marginLeft: checked === true ? "1%" : "30%",
                        color: '#fff',

                        fontSize: '20px',
                        width: "1%"
                    }}
                    >
                        {checked === false ? (
                            <KeyboardArrowRight onClick={handleChange} sx={{ fontSize: '100px' }} />
                        ) : (
                            <KeyboardArrowLeft onClick={handleChange} sx={{ fontSize: '90px' }} />
                        )}
                    </Button>
                }
            />
            <div>
                <Collapse sx={{ background: "#009940", textAlign: 'center', height: 482}} orientation="horizontal" in={checked} collapsedSize={32}>
                    {
                        checked === true ?
                            <img style={{ marginTop: "-2%", width: "95%", marginLeft: "4%" }} src={urlBase +"/api"+ banners[indexInfo].FileDatainfo} alt={banners[indexInfo].title} />
                            :
                            <p style={{ writingMode: "vertical-rl", color: "#ffff", fontWeight: "bold", fontFamily: 'Montserrat', fontSize: 20, marginLeft: "-1%", height: "100%" }}>Más información</p>
                    }
                </Collapse>
            </div>
        </Box>

    )

    useEffect(() => {
        window.addEventListener('resize', resize);

        getBanner();
    }, []);
    return (
        <div>
            <div className="headers">
                <Headers index={2} />
            </div>
            <br /><br /><br /><br />
            {banners.length >= 3 ?
                <div>
                    <div >
                        {indexImagA === 0 ?
                            <img style={{ width: "100%" }} src={urlBase +"/api"+ banners[0].File} onClick={() => {setIndexInfo(0); setIndexImagA(1); setIndexImagB(0); setIndexImagC(0);setChecked(false); }} alt={banners[0].title} className="image-opacity" /> :
                            <div>
                                <img style={{ width: "100%" }} src={urlBase +"/api"+ banners[0].Fileinfo} onClick={() => { setIndexImagA(0) }} alt={banners[0].title} className="image-opacity" />
                                {bannerinfo}
                            </div>
                        }
                    </div>
                    <br />

                    <div >
                        {indexImagB === 0 ?
                            <img style={{ width: "100%" }} src={urlBase +"/api"+ banners[1].File} onClick={() => {setIndexInfo(1); setIndexImagA(0); setIndexImagB(1); setIndexImagC(0);setChecked(false);}} alt={banners[1].title} className="image-opacity" /> :
                            <div>

                                <img style={{ width: "100%" }} src={urlBase +"/api"+ banners[1].Fileinfo} onClick={() => { setIndexImagB(0) }} alt={banners[1].title} className="image-opacity" />
                                {bannerinfo}
                            </div>
                        }
                    </div><br />

                    <div >
                        {indexImagC === 0 ?
                            <img style={{ width: "100%" }} src={urlBase +"/api"+ banners[2].File} onClick={() => {setIndexInfo(2); setIndexImagA(0); setIndexImagB(0); setIndexImagC(1); setChecked(false);}} alt={banners[1].title} className="image-opacity" /> :
                            <div >
                                <img style={{ width: "100%" }} src={urlBase +"/api"+ banners[2].Fileinfo} onClick={() => { setIndexImagC(0) }} alt={banners[2].title} className="image-opacity" />
                                {bannerinfo}
                            </div>
                        }
                    </div><br />
                </div> : <p>Debe de agregar mas de tres banners</p>
            }
            <div className="fouter">
                <Fouter />
            </div>
        </div>
    )
}
export default Investigations
