import { Box, Card, IconButton, Modal } from '@mui/material';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import VisualizadorUpdate from './VisualizadorUpdate.js'
import {
  apiInitionUp,
    apiInvestigationUp,
      apiBitacoraUp,
        apiDocumentaryUp,
          apiEventsUp,
            apiGalleryUp,
              apiMapsUp,
                apiMeetingUp,
                  apiPathVideoUp,
                    apiReleasesUp,
   compUrlsadmin} from '../../controller/autentications/urlsServer.js';
import axios from 'axios';
export default function PodcastActions(rowId) {
  const type = rowId.type
  const dataMetadata=rowId.rowId.row;
  const [showMedia, setShowMedia] = useState(false);

  const hiddenMedia = () => {
    setShowMedia(!showMedia);
  }
  
  const deleteApi = async () => {
    var id = dataMetadata.id
    await axios.delete(
      type=== "Banner initions"?apiInitionUp+id:
      type === "Gallerys"? apiGalleryUp+id:
      type === "Banner maps"? apiMapsUp+id:
      type === "Path videos"? apiPathVideoUp+id:
      type === "Bannerinvestigations"? apiInvestigationUp+id:
      type === "Documentarys"? apiDocumentaryUp+id:
      type === "Bitacoras"? apiBitacoraUp+id:
      type === "Meetings"? apiMeetingUp+id:
      type === "Releasess"? apiReleasesUp+id:
      type === "Meeting"? apiMeetingUp+id:
      apiEventsUp + id
      , compUrlsadmin)
      .then(response => {
        console.log(response.data);
      });
  }

  

  const mediaPlay = (
    <div>
    <Card sx={{
      borderRadius: 5,
      width: '80%',
      overflowY: 'auto',
      height: '80%',
      backgroundColor: "#fff",
      position: 'absolute',
      top: '10%',
      left: '5%'
    }}>
      <div>
        <VisualizadorUpdate rowId={dataMetadata} type={type} new={false} />
      </div>
    </Card>
    </div>)

  return (
    <Box
      sx={{
        m: 2,
        position: 'relative',
      }}
    >
      <div>
        <IconButton sx={{ color: '#4CBAB7',width: 40,height: 40 }} onClick={() => { deleteApi(); alert('Se borro correctamente, actualiza la tabla') }}>
          <DeleteIcon sx={{ fontSize: 35, }} />
        </IconButton>
        
        <IconButton sx={{ color: '#4CBAB7', width: 40, height: 40}} onClick={() => { setShowMedia(!showMedia); }}>
          <VisibilityIcon sx={{ fontSize: '30px' }} />
        </IconButton>
      </div>

      <Modal
        open={showMedia}
        onClose={hiddenMedia}>
        {mediaPlay}
      </Modal>
    </Box>
  );
};


